// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fm_KL";
export var achievementsSlider__badge_10 = "fm_KW";
export var achievementsSlider__badge_11 = "fm_KX";
export var achievementsSlider__badge_12 = "fm_KY";
export var achievementsSlider__badge_13 = "fm_KZ";
export var achievementsSlider__badge_14 = "fm_K0";
export var achievementsSlider__badge_15 = "fm_K1";
export var achievementsSlider__badge_16 = "fm_K2";
export var achievementsSlider__badge_17 = "fm_K3";
export var achievementsSlider__badge_18 = "fm_K4";
export var achievementsSlider__badge_19 = "fm_K5";
export var achievementsSlider__badge_2 = "fm_KM";
export var achievementsSlider__badge_3 = "fm_KN";
export var achievementsSlider__badge_4 = "fm_KP";
export var achievementsSlider__badge_5 = "fm_KQ";
export var achievementsSlider__badge_6 = "fm_KR";
export var achievementsSlider__badge_7 = "fm_KS";
export var achievementsSlider__badge_8 = "fm_KT";
export var achievementsSlider__badge_9 = "fm_KV";
export var achievementsSlider__logo = "fm_KK";
export var achievementsSlider__swiperSliderWrapper = "fm_KJ";