// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cF_tt";
export var homeWebDevProcess__between = "cF_tv";
export var homeWebDevProcess__contentFive = "cF_tF";
export var homeWebDevProcess__contentFour = "cF_tD";
export var homeWebDevProcess__contentOne = "cF_tz";
export var homeWebDevProcess__contentThree = "cF_tC";
export var homeWebDevProcess__contentTwo = "cF_tB";
export var homeWebDevProcess__description = "cF_ty";
export var homeWebDevProcess__image = "cF_tw";
export var homeWebDevProcess__title = "cF_tx";